<template>
    <div>
        <div v-for="row in rows" :key="row" class="grid">
            <summary-card v-for="id in divide(ids, row-1, rows)" :key="id" :record-id="id" />
        </div>
    </div>
</template>

<script>
import SummaryCard from './Summary.vue'

export default {
    components: { SummaryCard },
    props: { ids: { default: [] }, columns: { default: 3 } },
    computed: {
        rows() {
            return Math.ceil(this.ids.length /  this.columns)
        }
    },
    methods: {
        divide(array, thisChunk, totalChunks) {
            const chunkSize = Math.floor(array.length / totalChunks)
            console.log(chunkSize)
            return array.slice(chunkSize * thisChunk, chunkSize * thisChunk + chunkSize)
        }
    }
}
</script>