<template>
  <main :dir="d12n.direction">
    <navigation />
    <div class="grid">
      <div v-if="payload && !payload.error">
      <table>
        <tr> <th colspan="2"> {{d12n.record.data}} </th> </tr>
        <tr> <td>{{d12n.record.platform}}</td> <td>twitter.com</td> </tr>
        <tr> <td>{{d12n.record.id}}</td> <td>{{id}}</td> </tr>
        <tr> <td>{{d12n.record.author}}</td> <td><router-link :to="termFilterHref(payload.author)">{{payload.author}}</router-link></td> </tr>
        <tr> <td>{{d12n.record.text}}</td> <td :dir="getDirection(payload.text)">{{payload.text}}</td> </tr>
        <tr> <td>{{d12n.record.posted}}</td> <td>{{formatDateTime(payload.original_time)}}</td> </tr>
        <tr> <td>{{d12n.record.tags}}</td> <terms :tags="payload.tags" /> </tr>
        <tr> <td>{{d12n.record.mentions}}</td> <terms :tags="payload.mentions" /> </tr>
        <tr> <td>{{d12n.record.likes}}</td> <td>{{payload.likes}}</td> </tr>
        <tr> <td>{{d12n.record.retweets}}</td> <td>{{payload.extra.retweets}}</td> </tr>
        <tr> <td>{{d12n.record.responses}}</td> <td>{{payload.responses}}</td> </tr>
      <tbody v-if="showThread"> 
        <tr> <th colspan="2"> {{d12n.record.thread}} </th> </tr>
        <tr v-if="payload.extra.thread.prev"> <td>{{d12n.record.threadPrevious}}</td> <td><router-link :to="threadPrevHref">{{payload.extra.thread.prev}}</router-link></td> </tr>
        <tr v-if="payload.extra.thread.next"> <td>{{d12n.record.threadNext}}</td> <td><router-link :to="threadNextHref">{{payload.extra.thread.next}}</router-link></td> </tr>
      </tbody>
        <tr> <th colspan="2"> {{d12n.record.metadata}} </th> </tr>
        <tr> <td> {{d12n.record.archived}} </td> <td>{{formatDateTime(payload.archive_time)}}</td> </tr>
        <tr> <td> {{d12n.record.originalUrl}} </td> <td><a target="_blank" :href="originalHref"><i class="fas fa-external-link-alt"></i></a></td> </tr>
        <tr> <td> {{d12n.record.jsonData}} </td> <td><a target="_blank" :href="jsonHref"><i class="fas fa-external-link-alt"></i></a></td> </tr>
        <tr> <td> {{d12n.record.inclusionRationale}} </td> <origin :origin="payload.origin" /> </tr>
      </table>
      </div>
      <div v-else-if="payload && payload.error">
        <p v-if="lang==='en'">Record <a target="_blank" :href="`https://twitter.com/_/status/${id}`">{{id}} <i class="fas fa-external-link-alt"></i></a> not found in archive.</p>
        <p v-else-if="lang==='ar'">
          لم يتم العثور على سجل <a target="_blank" :href="`https://twitter.com/_/status/${id}`">{{id}} <i class="fas fa-external-link-alt"></i></a> في الارشيف.
        </p>
      </div>
      <div aria-busy="true" v-else />
      <div class="media" v-if="payload && !payload.error">
        <!-- images -->
        <h1 v-if="hasImages">{{d12n.record.images}}</h1>
        <a v-for="image in images" :key="image.url" :href="image.url" target="_blank">
          <article>
            <img :src="image.url" style="width:100%" />
          </article>
        </a>
        <!-- videos -->
        <h1 v-if="hasVideos">{{d12n.record.videos}}</h1>
        <article v-for="video in videos" :key="video.url" >
          <video :src="video.url" controls />
        </article>
        <!-- external links -->
        <h1 v-if="hasExternalLinks">{{d12n.record.externalLinks}}</h1>
        <external-link v-for="link in payload.extra.external_links" :key="link" :link="link" />
        <!-- internal links -->
        <h1 v-if="hasInternalLinks">{{d12n.record.internalLinks}}</h1>
        <summary-card class="internal-link" v-for="link in payload.extra.internal_links" :key="link" :record-id="link" />
      </div>
    </div>
  </main>
</template>

<script>
import Navigation from "./Navigation.vue";
import * as api from './api.js'
import Terms from './Terms.vue';
import Origin from './Origin.vue';
import ExternalLink from './ExternalLink.vue';
import SummaryCard from './Summary.vue';
import recordMixin from './record-mixin.js'
import { formatDateTime, termFilterHref } from './common.js'
import { getDirection } from 'string-direction'

export default {
  components: { Navigation, Terms, Origin, ExternalLink, SummaryCard },
  asyncComputed: {
      async payload() {
        try {
          return await api.record(this.id)
        } catch(error) {
          return { error }
        }
      }
  },
  computed: {
    ...recordMixin,
    showThread() {
      return this.payload.extra.thread.next || this.payload.extra.thread.prev
    },
    threadPrevHref() {
      return `/${this.lang}/archive/${this.payload.extra.thread.prev}`
    },
    threadNextHref() {
      return `/${this.lang}/archive/${this.payload.extra.thread.next}`
    },
    id() {
      return this.$route.params.id;
    },
    originalHref() {
      return `https://twitter.com/${this.payload.author}/status/${this.id}`
    },
    jsonHref() {
      return api.jsonHref(this.id)
    }
  },
  methods: { formatDateTime, termFilterHref, getDirection }
};
</script>

<style scoped>
th {
  font-weight: bold;
  border: none;
}

.media h1 {
  font-size: 19px;
  color: #415462;
  margin-bottom: 1em;
}

article {
  padding: 0;
  margin-top: 0;
}

article video {
  width: 100%;
}
</style>

<style>
a.internal-link article {
  margin-top: 0;
}
</style>