<template>
  <div class="search">
    <input v-model="term" @keyup="termBoxKeyup" type="search" :placeholder="d12n.recordIndex.filterPlaceholder" />
    <div class="checkboxes">
      <label for="search-images"
        ><input v-model="images" type="checkbox" id="search-images" />
        <i class="fas fa-images"></i>
      </label>
      <label for="search-videos"
        ><input v-model="videos" type="checkbox" id="search-videos" />
        <i class="fas fa-video"></i>
      </label>
    </div>
    <button @click="doSearch" :enabled="!searching" :aria-busy="searching">
      <i v-if="!searching" class="fas fa-search"></i>
    </button>
  </div>
</template>

<script>
// import * as api from './api.js'

export default {
  props: { searching: { default: false } },
  data: () => {
    return { term: "", images: false, videos: false };
  },
  watch: {
    $route() {
      this.updateFormFromQuery();
    },
  },
  methods: {
    updateFormFromQuery() {
      this.term = this.$route.query.term || "";
      this.images = !!this.$route.query.images;
      this.videos = !!this.$route.query.videos;
    },
    termBoxKeyup(e) {
      if(e.key === 'Enter') {
        this.doSearch()
      }
    },
    doSearch() {
      const term = this.term;
      const images = this.images;
      const videos = this.videos;
      const path = this.$route.path;
      const query = {}
      if(term) query.term = term
      if(images) query.images = images
      if(videos) query.videos = videos
      this.$router.push({ path, query });
    },
  },
  created() {
      this.updateFormFromQuery()
  }
};
</script>

<style scoped>
.search {
  display: flex;
}

button {
  flex-shrink: 3;
}

.checkboxes {
  width: 100px;
  margin-left: 20px;
}
</style>