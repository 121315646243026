<template>
    <main :dir="d12n.direction">
        <navigation />
        <p> {{d12n.introduction}} </p>
    </main>
</template>

<script>
import Navigation from './Navigation.vue'
export default {
    components: { Navigation }
}
</script>