export default {
    en: {
        localeName: "English",
        direction: "ltr",
        title: "October 17 Archive",
        introduction: "A repository archiving the stories, images, and videos produced and spread over social media during October 17 Revolution in Lebanon and the year that followed. The archive originally ran from December 2019 to January 2021, scraping twitter for relevant tweets and copying their content and media to our own storage. The archive is now considered closed and no new records are being added.",
        navigation: {
            about: "About",
            otherLanguageName: "العربية",
            otherLanguageLang: 'ar'
        },
        status: {
            storage: "Storage",
            records: "Records",
            missingRecords: "Missing Records",
            mediaFiles: "Media Files",
            mediaSize: "Media Size",
            activeWork: "Active Work",
            gatherJobs: "Gather Jobs",
            extractJobs: "Extract Jobs",
            mediaJobs: "Media Jobs",
            scheduledWork: "Scheduled Work",
        },
        recordIndex: {
            filterPlaceholder: "hashtag, users",
            images: "Images",
            videos: "Videos",
            links: "Links",
            filter: "Filter",
            next: "Next",
            previous: "Previous"
        },
        record: {
            data: "Data",
            id: "ID",
            field: "Field",
            value: "Value",
            platform: "Platform",
            author: "Author",
            text: "Text",
            posted: "Posted",
            tags: "Tags",
            mentions: "Mentions",
            likes: "Likes",
            retweets: "Retweets",
            responses: "Responses",
            metadata: "Metadata",
            archived: "Archived",
            originalUrl: "Original URL",
            jsonData: "JSON Data",
            origin: "Origin",
            images: "Images",
            videos: "Videos",
            externalLinks: "External Links",
            internalLinks: "Internal Links",
            inclusionRationale: "Inclusion Rationale",
            thread: "Thread",
            threadNext: "Next",
            threadPrevious: "Previous"
        }
    },
    ar: {
        localeName: "العربية",
        direction: "rtl",
        title: "ارشيف ١٧ تشرين",
        introduction: "مخزن لالقصص والصور والفيديوهات التي انتجت ونشرت عبر الشبكات التواصل الاجتماعية خلال ثورة ١٧ تشرين الاول في لبنان والعام التالي. كان الارشيف نشطًا من كانون الاول ٢٠١٩ لكانون الثاني ٢٠٢١ وكان يبحث موقع تويتر كل يوم تلقائيا لتغاريدات ذات صلة لالثورة ويحفظها  ووسائطها الى مخزننا الخاص. يعتبر مشروع البحث والحفظ كامل ولن يحفظ اي تغريد جديد.",
        navigation: {
            about: "عن",
            otherLanguageName: "English",
            otherLanguageLang: 'en'
        },
        status: {
            storage: "تخزين",
            records: "السجلات",
            missingRecords: "السجلات المفقودة",
            mediaFiles: "عدد الملفات الإعلامية",
            mediaSize: "حجم الملفات الإعلامية",
            activeWork: "العمل الحالي",
            gatherJobs: "عدد العمليات التجميع",
            extractJobs: "عدد العمليات الاستخلاص",
            mediaJobs: "عدد العمليات الاعلام",
            scheduledWork: "العمليات المقررة",
        },
        recordIndex: {
            filterPlaceholder: "الوسوم، أسماء",
            images: "صور",
            videos: "فيديوهات",
            links: "الروابط",
            filter: "تصفية",
            next: "التالي",
            previous: "السابق"
        },
        record: {
            data: "البيانات",
            id: "المعرف",
            field: "Field",
            value: "Value",
            platform: "منبر",
            author: "الكاتب",
            text: "النص",
            posted: "تاريخ النشر",
            tags: "الوسوم",
            mentions: "تذكيرات",
            likes: "لايكات",
            retweets: "إعادات نشر",
            responses: "ردود",
            metadata: "البيانات الوصفية",
            archived: "تاريخ الارشفة",
            originalUrl: "عنوان الاصلي",
            jsonData: "البيانات بتنسيق JSON",
            origin: "المصدر",
            images: "صور",
            videos: "فيديوهات",
            externalLinks: "روابط خارجية",
            internalLinks: "روابط داخلية",
            inclusionRationale: "سبب التضمين",
            thread: "الحديث",
            threadNext: "التالي",
            threadPrevious: "السابق"
        }
    }
}