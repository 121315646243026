import Vue from 'vue'
import App from './App.vue'
import router from './router'
import AsyncComputed from 'vue-async-computed'
import d12n from './d12n.js'

Vue.use(AsyncComputed)
Vue.use(d12n)

new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
  