<template>
  <td>
    <span v-for="term in tags" :key="term" class="term">
      <router-link :to="termFilterHref(term)">{{ term }}</router-link>
    </span>
  </td>
</template>

<script>
import { termFilterHref } from './common.js'

export default {
  props: ['tags'],
  methods: { termFilterHref  }
};
</script>

<style scoped>
span.term::before {
  content: " ";
}
</style>