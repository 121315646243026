<template>
    <td v-if="lang==='en'">
        <span v-if="type === 'lebanonprotests.com-xlsx-import'">
            Imported from <a href="http://lebanonprotests.com">lebanonprotests.com</a> <a :href="origin.url">spreadsheet</a>
        </span>
        <span v-if="type === 'twitter-hashtag-on-date'">
            Twitter hashtag search for <router-link :to="termFilterHref(origin.hashtag)">{{origin.hashtag}}</router-link> on date {{origin.date}}
        </span>
        <span v-if="type === 'twitter-account-on-date'">
            Twitter account search for <router-link :to="termFilterHref(origin.account)">{{origin.account}}</router-link> on date {{origin.date}}
        </span>
        <span v-if="type === 'followed-link'">
            Followed link from <router-link :href="followedLinkHref">twitter.com/{{origin.source}}</router-link>
        </span>
    </td>
    <td v-else-if="lang==='ar'">
        <span v-if="type === 'lebanonprotests.com-xlsx-import'">
            مستورد من <a :href="origin.url">جدول</a> <a href="http://lebanonprotests.com">lebanonprotests.com</a>
        </span>
        <span v-if="type === 'twitter-hashtag-on-date'">
            بحث عن تغريدات تحتوي الوسم <router-link :to="termFilterHref(origin.hashtag)">{{origin.hashtag}}</router-link> في التاريخ {{formatDateLong(origin.date)}}
        </span>
        <span v-if="type === 'twitter-account-on-date'">
            بحث عن تغريدات من الحساب <router-link :to="termFilterHref(origin.account)">{{origin.account}}</router-link> في التاريخ {{formatDateLong(origin.date)}}
        </span>
        <span v-if="type === 'followed-link'">
            تبع رابط من <router-link :href="followedLinkHref">twitter.com/{{origin.source}}</router-link>
        </span>
    </td>
</template>

<script>
import { termFilterHref, formatDateLong } from './common.js'

export default {
    props: ['origin'],
    computed: {
        followedLinkHref() {
            return `/${this.lang}/archive/${this.origin.source}`
        },
        type() {
            return this.origin.type
        }
    },
    methods: { termFilterHref, formatDateLong }
}
</script>