import strings from './strings.js'

export default {
    install(Vue) {
        Vue.mixin({
            computed: {
                lang() {
                    return this.$route.params.lang || 'ar'
                },
                d12n() {
                    return strings[this.lang]
                }
            }
        })
    }
}