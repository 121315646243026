import moment from 'moment'

moment.updateLocale('ar', {
    months:
          ["كانون الثاني", "شباط", "آذار", "نيسان", "أيار", "حزيران", "تموز", "آب", "أيلول", "تشرين الأول", "تشرين الثاني", "كانون الأول"],
    monthsShort:
        ["ك٢", "شباط", "آذار", "نيسان", "أيار", "حزيران", "تموز", "آب", "أيلول", "ت١", "ت٢", "ك١"]
})

export function formatDateTime(d) {
    moment.locale(this.lang)
    return d ? moment(d).utc().format('MMMM Do YYYY hh:mm:ss a') : d
}

export function formatDate(d) {
    moment.locale(this.lang)
    return d ? moment(d).utc().format('MMM Do YYYY') : d
}

export function formatDateLong(d) {
    moment.locale(this.lang)
    return d ? moment(d).utc().format('MMMM Do YYYY') : d
}

export function termFilterHref(term) {
    return `/${this.lang}/archive?term=${term}`
}