<template>
  <nav :dir="d12n.direction">
    <ul>
      <li><strong><router-link :to="`/${lang}/archive/`">{{d12n.title}}</router-link></strong></li>
    </ul>
    <ul>
      <li><router-link :to="`/${lang}/about`">{{d12n.navigation.about}}</router-link></li>
      <li><router-link :to="otherLanguageHref">{{d12n.navigation.otherLanguageName}}</router-link></li>
    </ul>
  </nav>
</template>

<script>
export default {
  computed: {
    otherLanguageHref() {
      return this.$route.path.replace(`/${this.lang}/`, `/${this.d12n.navigation.otherLanguageLang}/`)
    }
  }
}
</script>

<style scoped>
strong a {
  color: #415462;
}
</style>