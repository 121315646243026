<template>
  <main>
    <navigation />
    <search :searching="searching" />
    <summaries :ids="ids" />
    <pagination :ids="ids" :searching="searching" />
  </main>
</template>

<script>
import Navigation from "./Navigation.vue";
import Search from "./Search.vue";
import Summaries from "./Summaries.vue";
import Pagination from "./Pagination.vue";
import * as api from "./api.js";

export default {
  data: () => {
    return { ids: [], searching: false };
  },
  components: { Navigation, Search, Summaries, Pagination },
  computed: {
    page() {
      return this.$route.query.page || 1
    },
    showLessButton() {
      return this.page > 0
    },
    showMoreButton() {
      return this.ids.length > 0
    }
  },
  methods: {
    previousPage() {
      let query = { ...this.$route.query };
      query.page = parseInt(query.page)
      query.page -= 1
      this.$router.push({ query })
    },
    nextPage() {
      let query = { ...this.$route.query };
      query.page = parseInt(query.page || this.page)
      query.page += 1
      this.$router.push({ query })
    },
    filter() {
      this.searching = true
      api.search(this.$route.query).then((ids) => {
        this.searching = false
        this.ids = ids;
      });
    },
  },
  watch: {
    $route() {
      this.filter();
    },
  },
  created() {
    this.filter();
  },
};
</script>

<style scoped>
video {
  width: 100%;
  height: 100%;
}

img {
  width: 100%;
}
</style>