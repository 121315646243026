<template>
  <div class="grid">
    <button
      v-if="showLessButton"
      @click="previousPage"
      :aria-busy="searching"
      :enabled="!searching"
    >
      <i v-if="!searching" class="fas fa-angle-double-left"></i>
    </button>
    <button
      v-if="showMoreButton"
      @click="nextPage"
      :aria-busy="searching"
      :enabled="!searching"
    >
      <i v-if="!searching" class="fas fa-angle-double-right"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: ['ids', 'searching'],
  computed: {
    page() {
      return this.$route.query.page || 1;
    },
    showLessButton() {
      return this.page > 1;
    },
    showMoreButton() {
      return this.ids.length > 0;
    },
  },
  methods: {
    previousPage() {
      let query = { ...this.$route.query };
      query.page = parseInt(query.page);
      query.page -= 1;
      this.$router.push({ query });
    },
    nextPage() {
      let query = { ...this.$route.query };
      query.page = parseInt(query.page || this.page);
      query.page += 1;
      this.$router.push({ query });
    },
  },
};
</script>