import "dotenv/config"
import axios from "axios"

const postsPerPage = 90
const HOST_PREFIX = process.env.VUE_APP_HOST_PREFIX || ''

const recordCache = new Map()

export function jsonHref(id) {
    return `${HOST_PREFIX}/api/record/${id}`
}

export async function record(id) {
    if(recordCache.has(id)) {
        console.log('record cache hit')
        return recordCache.get(id)
    }
    
    const url = jsonHref(id)
    const result = (await axios.get(url)).data
    recordCache.set(id, result)
    return result
}

const searchCache = new Map()

export async function search(query) {
    const key = JSON.stringify(query)
    if(searchCache.has(key)) {
        console.log('search cache hit')
        return searchCache.get(key)
    }

    const params = { postsPerPage, ...query }
    const url = `${HOST_PREFIX}/api/search`
    const { data } = await axios.get(url, { params })
    searchCache.set(key, data)
    return data
}