<template>
    <a target="_blank" :href="link" >
        <article v-if="!metadata" aria-busy="true" />
        <article :dir="dir" v-else>
            <img v-if="imageSrc" :src="imageSrc" style="width:100%" />
            <footer>
                <h1>{{metadata.title}}</h1>
                <p>{{description}}</p>
                <i class="fas fa-external-link-alt"></i> <em>{{link}}</em>
            </footer>
        </article>
    </a>
</template>

<script>
// TODO replace axios api
import axios from 'axios'
import { getDirection } from 'string-direction'

export default {
    props: ['link'],
    data: () => {
        return { metadata: null }
    },
    computed: {
        dir() {
            return this.metadata?.title ? getDirection(this.metadata.title) : "ltr"
        },
        description() {
            return this.metadata.description || this.metadata.open_graph?.description
        },
        imageSrc() {
            return this.metadata.open_graph?.images[0].url
        }
    },
    created() {
        axios.get(`http://localhost:3000/internal/unfurl?url=${this.link}`).then(({data}) => {
            this.metadata = data
        }).catch(() => {
            this.metadata = {  }
        })
    }
}
</script>

<style scoped>
article[aria-busy] {
    padding: 50px;
}

article {
    margin-top: 0;
    padding: 0;
}

footer {
    margin: 0;
}

footer p {
    color: #415462;
}

h1 {
    font-size: 19px;
    padding: 0;
    margin-bottom: 1em;
}
</style>