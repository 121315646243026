export default {
    hasImages() {
        return this.images.length > 0
    },
    hasVideos() {
        return this.videos.length > 0
    },
    images() {
        return this.payload.media.filter(m => m.type.startsWith("image"))
    },
    videos() {
        return this.payload.media.filter(m => m.type.startsWith("video"))
    },
    hasExternalLinks() {
        return this.payload.extra.external_links.length > 0
    },
    hasInternalLinks() {
        return this.payload.extra.internal_links.length > 0
    },
    recordHref() {
        return `/${this.lang}/archive/${this.payload.id}`
    }
}