import Vue from 'vue';
import Router from 'vue-router'
import Archive from './Archive.vue'
import About from './About.vue'
import Record from './Record.vue'

Vue.use(Router)

export default new Router({
    routes: [
      {
        path: "/",
        redirect: "/ar/archive"
      },
      {
        path: '/:lang/about',
        component: About,
      },
      {
        path: '/:lang/archive',
        component: Archive,
      },
      {
        path: '/:lang/archive/:id',
        component: Record,
      },
    ],
    mode: 'history'
  });  