<template>
  <article aria-busy="true" v-if="!payload" />
  <router-link :to="recordHref" v-else>
  <article :dir="d12n.direction">
    <img v-if="images.length > 0" :src="images[0].url" />
    <p> <em dir="ltr">@{{payload.author}}</em> <span :dir="textDir" v-html="processedText"></span> </p>
    <footer>
        <strong><i class="fab fa-twitter"></i> {{formatDate(payload.original_time)}}</strong>
        <span class="stat" v-if="payload.likes"><i class="fas fa-heart"></i> {{payload.likes}} </span>
        <span class="stat" v-if="payload.extra.retweets"><i class="fas fa-retweet"></i> {{payload.extra.retweets}}</span>
        <span class="stat" v-if="payload.responses"><i class="fas fa-comment"></i> {{payload.responses}}</span>
        <span class="stat" v-if="payload.extra.external_links.length"> <i class="fas fa-link"></i> {{payload.extra.external_links.length}} </span>
        <span class="stat" v-if="images.length"> <i class="fas fa-images"></i> {{images.length}} </span>
        <span class="stat" v-if="videos.length"> <i class="fas fa-video"></i> {{videos.length}} </span>
    </footer>
  </article>
  </router-link>
</template>

<script>
import * as api from "./api.js";
import { getDirection } from 'string-direction'
import recordMixin from './record-mixin.js'
import { formatDate } from './common.js'

export default {
  props: ["recordId"],
  asyncComputed: {
    async payload() {
      return await api.record(this.recordId);
    },
  },
  computed: {
      ...recordMixin,
      textDir() {
          return getDirection(this.payload.text)
      },
      processedText() {
          return this.payload.text.replace(/(@\w+)/g, "<span dir='ltr'>$1</span>")
      }
  },
  methods: { formatDate }
};
</script>

<style scoped>
img {
    width:100%;
    max-height: 400px;
    object-fit: cover;
}

article[aria-busy] {
    padding: 50px;
    align-items: center;
}

article {
    margin-top: 0;
    padding: 0;
}


a article * {
    color: #415462;
}

a:hover {
    text-decoration: none !important;
}

a:focus {
    background-color: white;
}

article {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

article p {
    padding: 1em;
}

article footer {
    margin: 0;
}

span.stat::before {
    content: " ▪ "
}

</style>